import { graphql } from "gatsby";
import React from "react";
import Layout from "components/Layout";
import { Link } from "gatsby";
import LayoutFloating from "../components/LayoutFloating";
import { SITE_TITLE, SITE_DESCRIPTION } from "../lib/constants";

// 404 flash fix found here: https://github.com/gatsbyjs/gatsby/issues/5329#issuecomment-484741119
const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => {
  return (
    browser && (
      <Layout
        pageName="error-page"
        showHeader={false}
        siteTitle={SITE_TITLE}
        siteDescription={SITE_DESCRIPTION}
      >
        <LayoutFloating title={SITE_TITLE}>
          <section className="px-5 pt-3 pb-5 text-center">
            <h1>Page Not Found</h1>
            <p>Unable to locate this page.</p>
            <Link to="/" className="btn btn-lg btn-primary">
              Return home
            </Link>
          </section>
        </LayoutFloating>
      </Layout>
    )
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
